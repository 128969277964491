import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const linkStyle = {
    // textDecoration: "none",
    // color: "grey",
    // fontWeight: 800,
}

const TeleComPage = () => {
    return (

        <Layout>
            <Seo title="Tele-Communication"/>
            <div align="center">
                <h1>Tele-Communication</h1>
                <p>企業・コールセンターに必要なテレコミュニケーションシステムの技術支援サービスを提供しています。</p>
                <p>詳細は<Link to="https://docs.google.com/forms/d/e/1FAIpQLSfz1OeCBACBsngV45Hqamk6_osbbvRjFMQR955kDZAldrynaA/viewform" style={linkStyle} rel="noreferrer noopener" target="_blank">こちら</Link>からお問い合わせください。</p>
                <div className="cardListGrid">
                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage
                                src="../images/gatsby-icon.png"
                            />
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">
                                CloudPBX
                            </div>
                            <div className="card__overviewtext">
                                インターネットとPC/スマートフォンさえあれば利用できる工事の要らないPBX
                            </div>
                        </div>
                    </div>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage
                                src="../images/gatsby-icon.png"
                            />
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">
                                Gateway / SIP Trunk
                            </div>
                            <div className="card__overviewtext">
                                キャリアとエンドユーザーを接続するためのハイパフォーマンスSIPゲートウェイ
                            </div>
                        </div>
                    </div>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage
                                src="../images/gatsby-icon.png"
                            />
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">
                                CloudFAX
                            </div>
                            <div className="card__overviewtext">
                                iPhone/AndroidアプリでFAX送受信
                            </div>
                        </div>
                    </div>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage
                                src="../images/gatsby-icon.png"
                            />
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">
                                CRM連携
                            </div>
                            <div className="card__overviewtext">
                                ご利用中のCRMとPBXをシームレスに連携し、業務効率を改善します
                            </div>
                        </div>
                    </div>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage
                                src="../images/gatsby-icon.png"
                            />
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">
                                AutoCall
                            </div>
                            <div className="card__overviewtext">
                                電話番号リストへ効果的な自動発信システム
                            </div>
                        </div>
                    </div>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage
                                src="../images/gatsby-icon.png"
                            />
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">
                                Gateway接続サービス
                            </div>
                            <div className="card__overviewtext">
                                オフィスに設置されたPBXをインターネットに接続し、利便性を高めます
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default TeleComPage
